import "./SideBar.scss";
import { IoExit } from "react-icons/io5";
import SidebarItems from "./SidebarItems";
import { sidebarData } from "../../Data/Data";
const Sidebar = () => {
  return (
    <div className="d-flex  px-1 flex-column align-items-center">
      <br />
      {sidebarData.map((items, index) => {
        return <SidebarItems items={items} key={index} />;
      })}

      <div
        className=" w-100 ps-2 mt-2 d-flex align-items-center"
        onClick={() => {
          localStorage.removeItem("user");
          window.location.href = "/";
        }}
        style={{ cursor: "pointer" }}>
        <IoExit />
        <p className="my-auto ms-2">Logout</p>
      </div>
    </div>
  );
};

export default Sidebar;
