import { RxDashboard, RxCalendar } from "react-icons/rx";
import { VscTools } from "react-icons/vsc";
import { HiOutlineDocumentChartBar } from "react-icons/hi2";
export const sidebarData = [
  {
    name: "dashboard",
    link: "/dashboard",
    icon: <RxDashboard />,
  },
  {
    name: "tools",
    icon: <VscTools />,
    subMenu: [
      {
        name: "change password",
        link: "/change-password",
      },
      {
        name: "block vendor",
        link: "/block-vendor",
      },
      {
        name: "unblock vendor",
        link: "/unblock-vendor",
      },
    ],
  },
  {
    name: "master",
    icon: <HiOutlineDocumentChartBar />,
    subMenu: [
      {
        name: "Vendor master",
        link: "/vendor-master",
      },
      {
        name: "vendor list",
        link: "/vendor-list",
      },
      {
        name: "employee master",
        link: "/employee-master",
      },
      {
        name: "valley allocation",
        link: "/valley-allocation",
      },
      {
        name: "category master",
        link: "/category-master",
      },
      {
        name: "sub category master",
        link: "/sub-category-master",
      },
      {
        name: "item master",
        link: "/item-master",
      },
      {
        name: "super item master",
        link: "/super-item-master",
      },
      {
        name: "unit master",
        link: "/unit-master",
      },
      {
        name: "leave allocation",
        link: "/leave-allocation",
      },

      {
        name: "designation master",
        link: "/designation-master",
      },
      {
        name: "pay out",
        link: "/pay-out",
      },
    ],
  },
  {
    name: "day work out",
    icon: <RxCalendar />,
    subMenu: [
      {
        name: "order list",
        link: "/order-list",
      },

      {
        name: "reject order list",
        link: "/reject-order-list",
      },
      {
        name: "generate pay out",
        link: "/generate-pay-out",
      },
      {
        name: "customer complain",
        link: "/customer-complain",
      },
      {
        name: "resolve complain",
        link: "/resolve-complain",
      },
      {
        name: "capture order",
        link: "/capture-order",
      },
      {
        name: "employee pay out allocation",
        link: "/employee-pay-out-allocation",
      },
    ],
  },
];
