import { lazy } from "react";

const CategoryMaster = lazy(() =>
  import("./pages/Master/CategoryMaster/CategoryMaster")
);
const ItemMaster = lazy(() => import("./pages/Master/ItemMaster/ItemMaster"));
const SubCategoryMaster = lazy(() =>
  import("./pages/Master/SubCatMaster/SubCategoryMaster")
);
const SuperItemMaster = lazy(() =>
  import("./pages/Master/SuperItemMaster/SuperItemMaster")
);
const UnitMaster = lazy(() => import("./pages/Master/UnitMaster/UnitMaster"));
const VendorList = lazy(() => import("./pages/Master/VendorList/VendorList"));
const VendorMaster = lazy(() =>
  import("./pages/Master/VendorMaster/VendorMaster")
);

export const routes = [
  {
    path: "/category-master",
    element: <CategoryMaster />,
  },
  {
    path: "/sub-category-master",
    element: <SubCategoryMaster />,
  },
  {
    path: "/item-master",
    element: <ItemMaster />,
  },
  {
    path: "/unit-master",
    element: <UnitMaster />,
  },
  {
    path: "/super-item-master",
    element: <SuperItemMaster />,
  },
  {
    path: "/vendor-master",
    element: <VendorMaster />,
  },
  {
    path: "/vendor-list",
    element: <VendorList />,
  },
];
