import React, { useState } from "react";
import { IoCaretDownOutline, IoCaretUp } from "react-icons/io5";
import "./SideBar.scss";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { ToggleState } from "../../context/Toggle";
const SidebarItems = ({ items }) => {
  const [open, setOpen] = useState(false);
  const toggleState = useContext(ToggleState);
  const { toggleSidebar, setToggleSidebar } = toggleState;

  if (items.subMenu) {
    const handleLinks = () => {
      return window.innerWidth <= 950 ? setToggleSidebar(!toggleSidebar) : null;
    };
    return (
      <>
        <div className="sidebar-items my-1" onClick={() => setOpen(!open)}>
          <div className="sidebar-label-name d-flex align-items-center">
            {items.icon}

            <p className="my-auto ms-2">{items.name}</p>
          </div>
          {!open ? <IoCaretDownOutline /> : <IoCaretUp />}
        </div>
        {open ? (
          <motion.ul
            className="w-100"
            initial={{ height: 0 }}
            animate={{ height: "fit-content" }}
            transition={{
              type: "tween",
              duration: 0.5,
            }}
            style={{ overflow: "hidden" }}>
            {items.subMenu.map((items, index) => {
              return (
                <motion.li
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="my-2"
                  key={index}
                  onClick={() => handleLinks()}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "text-warning fw-bold" : "text-light"
                    }
                    style={{
                      textDecoration: "none",
                      color: "#ffff",
                      textTransform: "capitalize",
                    }}
                    to={items.link}>
                    {items.name}
                  </NavLink>
                </motion.li>
              );
            })}
          </motion.ul>
        ) : null}
      </>
    );
  } else {
    return (
      <NavLink
        to={items.link}
        className="sidebar-items"
        style={{ textDecoration: "none", color: "#ffff" }}>
        <div className="sidebar-label-name d-flex align-items-center">
          {items.icon}
          <p className="my-auto ms-2">{items.name}</p>
        </div>
      </NavLink>
    );
  }
};

export default SidebarItems;
