import axios from "axios";
import { createContext, useState } from "react";
export const ToggleState = createContext();
const Toggle = ({ children }) => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hindiText, setHindiText] = useState("");
  const [uploadImage, setUploadImage] = useState("");
  const [prevImage, setPrevImage] = useState("");
  const [toggleSidebar, setToggleSidebar] = useState(
    window.innerWidth <= 950 ? true : false
  );
  const [auth, setAuth] = useState(false);

  const fetchTableData = async (api, sector, mainCatOption) => {
    setLoading(true);
    const body = new FormData();
    body.append("sector", sector);
    body.append("mcat_id", mainCatOption);
    body.append("api", "sdgfwp49f4923d3287slhgw");

    const { data } = await axios.post(api, body);
    setTableData(data);
    setLoading(false);
  };

  // ==========================translate text start=========================

  const getLanguageSource = async (lang) => {
    try {
      const body = new FormData();
      body.append("q", lang);
      const response = await axios.post(
        `https://libretranslate.de/detect`,
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    // axios
    //   .post(`https://libretranslate.de/detect`, body, {
    //     headers: { "Content-Type": "multipart/form-data" },
    //   })
    //   .then((response) => {
    //     console.log(response);
    //     translateText(response.data[0].language, lang);
    //   });
  };
  const translateText = (text, lang) => {
    console.log(text, lang);
    let data = {
      q: lang,
      source: text,
      target: "hi",
    };
    axios.post(`https://libretranslate.de/translate`, data).then((response) => {
      setHindiText(response.data.translatedText);
    });
  };

  // =======================translate text end=========================
  return (
    <ToggleState.Provider
      value={{
        toggleSidebar,
        setToggleSidebar,
        auth,
        setAuth,
        fetchTableData,
        tableData,
        loading,
        getLanguageSource,
        hindiText,
        uploadImage,
        setUploadImage,
        prevImage,
        setPrevImage,
      }}>
      {children}
    </ToggleState.Provider>
  );
};

export default Toggle;
